import { useTranslation } from 'react-i18next'
import CompaniesIcon from '@assets/icons/company.svg'
import {
  AlertIcon,
  AssetDetailsIcon,
  BrandIcon,
  CartIcon,
  CategoryIcon,
  OrderCloseIcon,
  OrdersIcon,
  PriorityIcon,
  SpaceIcon,
  SubcategoryIcon,
  VendorIcon,
} from '@dots/uikit/icons'
import { NavigationLink } from '@share/components/ui'
import { ROUTES } from '@share/constants'

import { useSidebarStore } from '../../../model'
import { Area } from '../../atoms'

import s from './midbar.module.scss'

export const MidBar: React.FC = () => {
  const { t } = useTranslation()
  const { isCollapsed } = useSidebarStore()

  return (
    <div className={s.root}>
      <Area title={t('sidebar.area1.title')}>
        <NavigationLink
          to={ROUTES.ORDERS.root}
          label={t('sidebar.orders')}
          icon={OrdersIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.vendors}
          label={t('sidebar.vendors')}
          icon={VendorIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.catalog}
          label={t('sidebar.catalog')}
          icon={CartIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.categories.root}
          label={t('sidebar.categories')}
          icon={CategoryIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.subcategories.root}
          label={t('sidebar.subcategories')}
          icon={SubcategoryIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.brands.root}
          label={t('sidebar.brands')}
          icon={BrandIcon}
          collapsed={isCollapsed}
        />
      </Area>
      <Area title={t('sidebar.area2.title')}>
        <NavigationLink
          to={ROUTES.companies}
          label={t('sidebar.companies')}
          icon={CompaniesIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.companiesAdmins}
          label={t('sidebar.companiesAdmins')}
          icon={SpaceIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.updates.root}
          label={t('sidebar.updates')}
          icon={AlertIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.reports.root}
          label={t('sidebar.reports')}
          icon={AssetDetailsIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.slaConfig.risk}
          label={t('sidebar.orders-at-risk')}
          icon={OrderCloseIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.slaConfig.riskChart}
          label={t('sidebar.orders-at-risk-chart')}
          icon={PriorityIcon}
          collapsed={isCollapsed}
        />
      </Area>
    </div>
  )
}
