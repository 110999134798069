import * as yup from 'yup'

import { assetConditionStatesTypeSchema } from '../../asset/asset-condition-state.schemas'

export const possibleVendorResponseProvideType = [
  'CAN_PROVIDE_FULLY',
  'CAN_PROVIDE_PARTIALLY',
  'CAN_PROVIDE_ALTERNATIVE',
  'CANNOT_PROVIDE',
] as const

export type VendorResponseProvideTypes = (typeof possibleVendorResponseProvideType)[number]

export const vendorResponseProvideTypeSchema = yup
  .mixed<VendorResponseProvideTypes>()
  .oneOf(possibleVendorResponseProvideType)

export const VRFVendorResponseSchema = yup.object({
  id: yup.string().defined(),
  provideType: vendorResponseProvideTypeSchema.defined(),
  etaFrom: yup.string().defined().nullable(),
  etaTo: yup.string().defined().nullable(),
  unitPrice: yup.number().defined().nullable(),
  unitPriceInUSD: yup.number().defined().nullable(),
  condition: assetConditionStatesTypeSchema.defined().nullable(),
  notes: yup.string().defined().nullable(),
  keyboardLayout: yup.string().defined().nullable(),
  powerSupplyType: yup.string().defined().nullable(),
  quantity: yup.number().defined().nullable(),
  mpn: yup.string().defined().nullable(),
  name: yup.string().defined().nullable(),
  warranty: yup
    .object({
      id: yup.string().defined(),
      name: yup.string().defined(),
    })
    .defined()
    .nullable(),
})

export type VRFVendorResponseModel = yup.InferType<typeof VRFVendorResponseSchema>
